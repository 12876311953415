import request, { MarkcoinRequest } from '@/plugins/request'
import {
  CopyTradingProjectListReq,
  CopyTradingProjectListResp,
  CopyTradingUserFollowerApplyReq,
  CopyTradingUserInfoReq,
  CopyTradingUserInfoReqResp,
  CopyTradingUserTakerApplyReq,
  CopyTradingVacancyReminderSubscribeReq,
} from '@/typings/api/copy-trading/square'
import {
  YapiPostV1FollowProjectCreateApiRequest,
  YapiPostV1FollowProjectCreateApiResponse,
} from '@/typings/yapi/FollowProjectCreateV1PostApi'
import {
  YapiGetV1FollowProjectCurrentApiRequest,
  YapiGetV1FollowProjectCurrentApiResponse,
} from '@/typings/yapi/FollowProjectCurrentV1GetApi'
import {
  YapiGetV1FollowProjectHistoryApiRequest,
  YapiGetV1FollowProjectHistoryApiResponse,
} from '@/typings/yapi/FollowProjectHistoryV1GetApi'
import {
  YapiGetV1FollowTradePairListApiRequest,
  YapiGetV1FollowTradePairListApiResponse,
} from '@/typings/yapi/FollowTradePairListV1GetApi'
import {
  YapiGetV1FollowUserFollowerApplyApiRequest,
  YapiGetV1FollowUserFollowerApplyApiResponse,
} from '@/typings/yapi/FollowUserFollowerApplyV1GetApi'

/**
 * [用户当前状态↗](https://yapi.nbttfc365.com/project/100/interface/api/19813)
 * */
export const getFollowUserStatus: MarkcoinRequest<CopyTradingUserInfoReq, CopyTradingUserInfoReqResp> = params => {
  return request({
    path: '/v1/follow/user/status',
    method: 'GET',
    params,
  })
}

/**
 * [开通带单员身份↗](https://yapi.nbttfc365.com/project/100/interface/api/19825)
 * */
export const postTradeLeaderApply: MarkcoinRequest<CopyTradingUserTakerApplyReq, boolean> = data => {
  return request({
    path: '/v1/follow/user/takerApply',
    method: 'POST',
    data,
  })
}

/**
 * [开通跟单员↗](https://yapi.nbttfc365.com/project/100/interface/api/19819)
 * */
export const getFollowUserFollowerApply: MarkcoinRequest<CopyTradingUserFollowerApplyReq, boolean> = params => {
  return request({
    path: '/v1/follow/user/followerApply',
    method: 'GET',
    params,
  })
}

/**
 * [商户的跟单币对配置↗](https://yapi.nbttfc365.com/project/100/interface/api/19801)
 * */
export const getFollowTradePairList: MarkcoinRequest<
  YapiGetV1FollowTradePairListApiRequest,
  YapiGetV1FollowTradePairListApiResponse['data']
> = params => {
  return request({
    path: '/v1/follow/tradePairList',
    method: 'GET',
    params,
  })
}

/**
 * [创建带单项目↗](https://yapi.nbttfc365.com/project/100/interface/api/19831)
 * */
export const postFollowProjectCreate: MarkcoinRequest<
  YapiPostV1FollowProjectCreateApiRequest,
  YapiPostV1FollowProjectCreateApiResponse['data']
> = data => {
  return request({
    path: '/v1/follow/project/create',
    method: 'POST',
    data,
  })
}

/**
 * 编辑带单项目
 */
export const postFollowProjectEdit: MarkcoinRequest<
  YapiPostV1FollowProjectCreateApiRequest,
  YapiPostV1FollowProjectCreateApiResponse['data']
> = data => {
  return request({
    path: '/v1/follow/project/edit',
    method: 'POST',
    data,
  })
}

/**
 * [当前项目列表↗](https://yapi.nbttfc365.com/project/100/interface/api/19837)
 * */
export const getFollowProjectCurrent: MarkcoinRequest<
  CopyTradingProjectListReq,
  CopyTradingProjectListResp
> = params => {
  return request({
    path: '/v1/follow/project/current',
    method: 'GET',
    params,
  })
}

/**
 * [历史项目列表↗](https://yapi.nbttfc365.com/project/100/interface/api/19843)
 * */
export const getFollowProjectHistory: MarkcoinRequest<
  CopyTradingProjectListReq,
  CopyTradingProjectListResp
> = params => {
  return request({
    path: '/v1/follow/project/history',
    method: 'GET',
    params,
  })
}

/**
 * 空位提醒订阅或取消
 */
export const getFollowProjectVacancyReminderSubscribe: MarkcoinRequest<
  CopyTradingVacancyReminderSubscribeReq,
  boolean
> = params => {
  return request({
    path: '/v1/follow/person/vacancyReminder',
    method: 'GET',
    params,
  })
}
